<template>
  <div class="text-primaryDark relative pb-20">
    <top-nav-bar />
    <transition name="fade">
      <div v-if="loading" class="absolute inset-0 bg-white bg-opacity-80">
        <loader class="absolute center-h-v" />
      </div>
    </transition>
    <transition name="fade">
      <div v-if="success" class="bg-black fixed bg-opacity-80 inset-0 z-50 h-screen w-full">
        <div class="fixed center-h-v bg-white shadow-2xl sm:w-1/4 w-3/4 p-5">
          <img class="h-14 mx-auto object-contain" src="@/assets/icons/check.png" />
          <p class="text-center text-sm font-semibold py-5">
            Your password has been changed successfully. Please login again.
          </p>

          <button @click="logOut()" class="bg-primaryDark w-full text-white font-bold py-2 px-4 rounded">
            Login
          </button>
        </div>
      </div>
      >
    </transition>
    <div class="flex justify-center mt-10">
      <div class="sm:w-1/2 w-full">
        <img class="h-16 mx-auto object-contain" src="@/assets/icons/lock.png" />
        <p class="text-center py-3 text-2xl font-semibold">
          Reset your password
        </p>
        <p class="text-center pb-3 text-sm">
          In order to protect your account, make sure the password:
        </p>
        <div class="flex justify-center">
          <ul class="list-disc text-sm font-bold">
            <li>Has an upper and lower case letter.</li>
            <li>Contains a special character.</li>
            <li>Is at least 6 characters long.</li>
            <li>Has a number.</li>
          </ul>
        </div>
        <form @submit.prevent="changePassword">
          <div class="flex flex-col gap-3 justify-center w-1/2 mx-auto mt-3">
            <div>
              <label class="text-xs">New Password</label>
              <input v-model="data.Password" required type="password"
                class="p-1 mt-0.5 block w-full bg-gray-200 focus:bg-white" />
              <span v-if="modelError && modelError['model.' + 'Password']" class="text-red-500 text-sm block w-full">
                {{ modelError["model." + "Password"][0] }}
              </span>
            </div>
            <div>
              <label class="text-xs">Confirm Password</label>
              <input v-model="ConfirmPassword" required type="password"
                class="p-1 mt-0.5 block w-full bg-gray-200 focus:bg-white" />
              <span v-if="passwordDontMatch" class="text-red-500 text-sm block w-full">Passwords do not match
              </span>
            </div>
          </div>
          <div class="w-full flex justify-center mt-8">
            <button class="
                text-sm
                bg-primaryDark
                hover:bg-buttonColor
                text-white
                sm:w-1/3
                w-1/2
                font-bold
                py-2
                px-4
                rounded
              ">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import TopNavBar from "../components/dashboardLayout/topNavBar.vue";
import Loader from "../components/loader/loader.vue";
import axios from "axios";
var qs = require("qs");

export default {
  name: "ResetPassword",
  components: {
    TopNavBar,
    Loader,
  },
  metaInfo: {
    title: "LipaLink | Reset Password",
  },
  data() {
    return {
      loading: false,
      success: false,
      modelError: {},
      passwordDontMatch: false,
      ConfirmPassword: null,
      data: {
        UserId: this.$route.query.UserId,
        Code: this.$route.query.Code,
        Password: null,
      },
    };
  },
  methods: {
    logOut() {
      this.$router.replace({ name: "login" });
    },
    changePassword() {
      this.passwordDontMatch = false;
      if (this.data.Password === this.ConfirmPassword) {
        this.loading = true;
        this.success = false;
        axios.post(`${this.$route.params.organization}/account/reset/password`, qs.stringify(this.data)).then(
          () => {
            this.loading = false;
            this.success = true;
          },
          (error) => {
            this.loading = false;
            if (
              error.response["data"] &&
              error.response["data"]["ModelState"]
            ) {
              this.modelError = error.response["data"]["ModelState"];
            }
          }
        );
      } else {
        this.passwordDontMatch = true;
      }
    },
  },
};
</script>